import React, { useMemo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import {
  formatCurrency,
  formatDistance,
  formatTime,
  formatWeight,
  format1,
  byId,
} from 'utils/utils';
import { getColor } from 'utils/mapsUtils';
import Checkbox from '@material-ui/core/Checkbox';
import { actionTypes } from './planningReducer';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const columns = [
  // { id: 'list', numeric: false, align: 'center', label: 'List' },
  { id: 'type', numeric: false, align: 'center', label: 'Type' },
  { id: 'sales', numeric: true, align: 'center', label: 'Sales' },
  { id: 'volume', numeric: true, align: 'center', label: 'Vol' },
  { id: 'weight', numeric: true, align: 'center', label: 'kg' },
  { id: 'drops', numeric: true, align: 'center', label: 'Dps' },
  { id: 'distance', numeric: true, align: 'center', label: 'km' },
  { id: 'service', numeric: true, align: 'center', label: 'Service' },
  { id: 'time', numeric: true, align: 'center', label: 'Total' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  cell: {
    padding: 4,
    paddingTop: 6,
    paddingBottom: 6,
  },
  sortIcon: {
    margin: 0,
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'scroll',
    height: 440,
  },
  tableBody: {},
  list: {
    fontWeight: 900,
  },
  warning: {
    background: red[100],
  },
  arrow: {
    position: 'absolute',
    fontSize: '7px',
    left: 0,
    width: 0,
    height: 0,
    borderTop: '1em solid transparent',
    borderBottom: '1em solid transparent',
    borderRight: '1em solid #3f51b5',
    marginLeft: '-1em',
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, onSelectAllClick, numSelected, rowCount } = props;
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            className={classes.cell}
            align={column.align}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === column.id}
              direction={order}
              classes={{
                icon: classes.sortIcon,
              }}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PlanningTable({
  selectedPicklists: selected,
  picklists,
  retailers,
  getVehicleDetails,
  dispatch,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();
  const [popperState, setPopperState] = useState({
    open: false,
    anchorEl: null,
    picklistIndex: null,
    vehicle: null,
  });
  const [arrowRef, setArrowRef] = useState(null);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const popper = useMemo(
    () => (
      <Popper
        id={popperState.open ? 'retaielrsList' : null}
        open={popperState.open}
        anchorEl={popperState.anchorEl}
        placement="right"
        popperOptions={{
          modifiers: {
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          },
        }}
      >
        {() => (
          // <Fade {...TransitionProps} timeout={100}>
          <>
            <span className={classes.arrow} ref={(node) => setArrowRef(node)} />
            <Paper>
              <PicklistRetailersTable
                picklist={byId(picklists, popperState.picklistIndex, 'index')}
                retailers={retailers}
                dispatch={dispatch}
                vehicle={popperState.vehicle}
              />
            </Paper>
          </>
          // </Fade>
        )}
      </Popper>
    ),
    [retailers, popperState, picklists, dispatch, arrowRef, classes.arrow]
  );

  const openRetailerList = useCallback((event, picklistIndex, vehicle) => {
    const { currentTarget } = event;
    setPopperState((state) => ({
      anchorEl: currentTarget,
      open: currentTarget === state.anchorEl ? !state.open : true,
      picklistIndex,
      vehicle,
    }));
  }, []);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = picklists.map((n) => n.index);
      dispatch({
        type: actionTypes.UPDATE_SELECTED_PICKLISTS,
        selectedPicklists: newSelecteds,
      });
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_SELECTED_PICKLISTS,
      selectedPicklists: [],
    });
  }

  function handleClick(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    dispatch({
      type: actionTypes.UPDATE_SELECTED_PICKLISTS,
      selectedPicklists: newSelected,
    });
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const getCell = (value, className, align, colIndex, onClick, style, warning) => (
    <TableCell
      key={colIndex}
      className={classNames(classes.cell, classes[className], { [classes.warning]: warning })}
      align={align}
      style={style ? style : {}}
      onClick={onClick}
    >
      {value}
    </TableCell>
  );

  return (
    <div className={classes.tableWrapper}>
      {popper}
      <Table className={classes.table}>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          rowCount={picklists.length}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody className={classes.tableBody}>
          {stableSort(picklists, getSorting(order, orderBy)).map((pl) => {
            const isItemSelected = isSelected(pl.index);
            const vehicle = getVehicleDetails(pl.vehicleTypeId);
            return (
              <TableRow hover tabIndex={-1} key={pl.index} selected={!isItemSelected}>
                {[
                  {
                    cmp: (
                      <Typography
                        className={classes.list}
                        style={{
                          color: isItemSelected ? getColor(pl.index).fillColor : '#757575',
                        }}
                      >
                        {pl.name}
                      </Typography>
                    ),
                    className: 'name',
                    align: 'center',
                    onClick: (event) => handleClick(event, pl.index),
                    style: { cursor: 'pointer' },
                  },
                  {
                    cmp: <Typography variant="caption">{vehicle.name}</Typography>,
                    className: 'vehicleName',
                    align: 'center',
                    onClick: (event) => openRetailerList(event, pl.index, vehicle),
                    style: { cursor: 'pointer' },
                  },
                  {
                    cmp: <Typography variant="caption">{formatCurrency(pl.sales)}</Typography>,
                    align: 'center',
                    className: 'sales',
                  },
                  {
                    cmp: (
                      <Typography variant="caption">{`${Math.trunc(
                        (100 * pl.volume) / vehicle.maxVolume
                      )}%`}</Typography>
                    ),
                    align: 'center',
                    className: 'volume',
                    style: {
                      background:
                        pl.volume <= vehicle.maxVolume * vehicle.volumeLimitRatio * 0.8
                          ? orange[100]
                          : pl.volume > vehicle.maxVolume * vehicle.volumeLimitRatio
                          ? red[100]
                          : pl.volume > vehicle.maxVolume * vehicle.volumeLimitRatio * 0.8
                          ? green[100]
                          : '',
                    },
                    warning: pl.volume > vehicle.maxVolume * vehicle.volumeLimitRatio,
                  },
                  {
                    cmp: <Typography variant="caption">{`${Math.trunc(pl.weight)}`}</Typography>,
                    align: 'center',
                    className: 'weight',
                    style: {
                      background:
                        pl.weight <= 0.8 * vehicle.maxWeight
                          ? orange[100]
                          : pl.weight > vehicle.maxWeight
                          ? red[100]
                          : pl.weight > vehicle.maxWeight * 0.8
                          ? green[100]
                          : '',
                    },
                    warning: pl.weight > vehicle.maxWeight,
                  },
                  {
                    cmp: (
                      <Typography variant="caption" color="primary">
                        {pl.retailerIds.length}
                      </Typography>
                    ),
                    align: 'center',
                    className: 'drops',
                    style: {
                      background:
                        pl.retailerIds.length <= 0.8 * vehicle.maxDropPoints
                          ? orange[100]
                          : pl.retailerIds.length > vehicle.maxDropPoints
                          ? red[100]
                          : pl.retailerIds.length > 0.8 * vehicle.maxDropPoints
                          ? green[100]
                          : '',
                    },
                  },
                  {
                    cmp: (
                      <Typography variant="caption">{formatDistance(pl.tripDistance)}</Typography>
                    ),
                    align: 'center',
                    className: 'distance',
                    style: {
                      background: vehicle.maxDistance
                        ? pl.tripDistance <= 0.8 * vehicle.maxDistance
                          ? orange[100]
                          : pl.tripDistance > vehicle.maxDistance
                          ? red[100]
                          : pl.tripDistance > 0.8 * vehicle.maxDistance
                          ? green[100]
                          : ''
                        : '',
                    },
                  },
                  {
                    cmp: (
                      <Typography variant="caption">
                        {formatTime(
                          pl.serviceTime +
                            (vehicle.loadingTime ?? 0) +
                            (vehicle?.serviceTime || 0) * pl.retailerIds.length
                        )}
                      </Typography>
                    ),
                    className: 'tripTime',
                    align: 'center',
                    style: { minWidth: 62, paddingRight: 8 },
                    warning: false,
                  },
                  {
                    cmp: (
                      <Typography variant="caption">
                        {formatTime(
                          pl.travelTime +
                            pl.serviceTime +
                            (vehicle.loadingTime ?? 0) +
                            (vehicle?.serviceTime || 0) * pl.retailerIds.length +
                            (pl.waitingTime || 0)
                        )}
                      </Typography>
                    ),
                    className: 'tripTime',
                    align: 'center',
                    style: {
                      minWidth: 62,
                      paddingRight: 8,
                      background:
                        pl.travelTime + pl.serviceTime + (pl.waitingTime || 0) <=
                        0.8 * vehicle.maxTripTime
                          ? orange[100]
                          : pl.travelTime + pl.serviceTime + (pl.waitingTime || 0) >
                            vehicle.maxTripTime
                          ? red[100]
                          : pl.travelTime + pl.serviceTime + (pl.waitingTime || 0) >
                            0.8 * vehicle.maxTripTime
                          ? green[100]
                          : '',
                    },
                  },
                ].map(
                  (
                    { cmp, className, align = 'right', onClick, style, warning = false },
                    colIndex
                  ) => getCell(cmp, className, align, colIndex, onClick, style, warning)
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const useStylesRetailersList = makeStyles(() => ({
  tableCell: {
    paddingRight: '8px !important',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
  },
}));

function PicklistRetailersTable({ picklist: { retailerIds }, retailers, vehicle, dispatch }) {
  const classes = useStylesRetailersList();

  const tc = useCallback(
    (text, align = 'center', color = 'textPrimary') => (
      <TableCell className={classes.tableCell} align={align}>
        <Typography variant="caption" color={color}>
          {text}
        </Typography>
      </TableCell>
    ),
    [classes]
  );

  const plRetailers = useMemo(() => retailerIds.map((rId) => retailers[rId]), [
    retailerIds,
    retailers,
  ]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {tc('RCode', undefined, 'primary')}
          {tc('Sales', 'right', 'primary')}
          {tc('Vol', 'right', 'primary')}
          {tc('Weight', 'right', 'primary')}
          {tc('Channel', undefined, 'primary')}
        </TableRow>
      </TableHead>
      <TableBody>
        {plRetailers
          .sort(({ sales: s1 }, { sales: s2 }) => s2 - s1)
          .map(({ id, code, sales, volume, weight, channel }) => (
            <TableRow key={id}>
              <TableCell
                className={classes.tableCell}
                align="center"
                onClick={() =>
                  dispatch({
                    type: actionTypes.OPEN_RETAILER_INFO_WINDOW,
                    retailerId: id,
                  })
                }
                style={{ cursor: 'pointer' }}
              >
                <Typography color="primary" variant="caption">
                  {code}
                </Typography>
              </TableCell>
              {tc(formatCurrency(sales), 'right')}
              {tc(`${format1((100 * volume) / vehicle.maxVolume)}%`, 'right')}
              {tc(formatWeight(weight), 'right')}
              {tc(channel, 'center', 'textSecondary')}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default PlanningTable;
