import { format } from 'date-fns';
import { useMemo } from 'react';
import { formatDate } from 'utils/utils';

// export function getPlans(params) {
//   return {
//     method: 'post',
//     url: '/delivery/polish/status',
//     data: {
//       date: format(params.date, 'yyyy-MM-dd'),
//       status: params.status,
//       filters: {
//         clientIncludes: params.ci,
//         branchIncludes: params.bi,
//         branchExcludes: params.be,
//       },
//     },
//   };
// }

export function useGetPlans(params) {
  return useMemo(
    () => ({
      method: 'post',
      url: '/delivery/polish/status',
      data: {
        date: format(params.date, 'yyyy-MM-dd'),
        status: params.status,
        filters: {
          clientIncludes: params.ci,
          branchIncludes: params.bi,
          branchExcludes: params.be,
        },
      },
    }),
    [params]
  );
}

// export const useGetAllPlans = date =>
//   useMemo(
//     () => ({
//       method: 'post',
//       url: '/delivery/polish/status',
//       data: {
//         date: format(date, 'yyyy-MM-dd'),
//         status: [ps.CREATED, ps.QUEUED, ps.PROCESSING, ps.COMPLETED, ps.FROZEN],
//       },
//     }),
//     [date]
//   );

export const useGetClients = () =>
  useMemo(
    () => ({
      method: 'get',
      url: '/delivery/polish/client',
    }),
    []
  );

export const useGetPrincipals = () =>
  useMemo(
    () => ({
      method: 'get',
      url: '/panzer/principal',
    }),
    []
  );

export function getPlan(id) {
  return {
    method: 'get',
    url: `/delivery/polish/plan/${id}`,
  };
}

export function useGetPlanDetails(id) {
  return useMemo(
    () => ({
      method: 'get',
      url: `/delivery/polish/plan/${id}/details`,
    }),
    [id]
  );
}

export const postPlanAction = (id, action, updatedAt, edits) => ({
  method: 'post',
  url: `/delivery/polish/plan/${id}/${action}`,
  params: {
    reference: updatedAt,
  },
  data: edits,
});

export const getPlanByDate = (id, date) => ({
  url: `/delivery/polish/plan/${id}/otherDate?date=${formatDate(date)}`,
  method: 'get',
});

export const fetchDistanceMatrix = (latlngs, nodeId, planId) => ({
  url: `/delivery/polish/distanceMatrix?nodeId=${nodeId}&planId=${planId}`,
  method: 'post',
  data: latlngs,
});

export const downloadPlan = (id) => ({
  method: 'get',
  url: `/delivery/polish/plan/${id}/download`,
  responseType: 'blob',
});

export const downloadOrderUpload = (id) => ({
  method: 'get',
  url: `/delivery/polish/orderupload?planId=${id}`,
  responseType: 'blob',
});

export const downloadInvoiceUpload = (id) => ({
  method: 'get',
  url: `/delivery/polish/plan/${id}/download`,
  responseType: 'blob',
});

export const uploadStaticPlan = (id, data) => ({
  method: 'post',
  url: `/delivery/polish/plan/${id}/static`,
  data,
});

export const postPlanActionv1 = (id) => (action, updatedAt, data) => {
  if (['start', 'cancel', 'save', 'freeze'].includes(action))
    return {
      method: 'post',
      url: `/delivery/polish/plan/${id}/${action}`,
      ...(updatedAt
        ? {
            params: {
              reference: updatedAt,
            },
          }
        : {}),
      ...(data ? { data } : {}),
    };

  if (action === 'reRun')
    return {
      method: 'post',
      url: `/delivery/polish/plan/${id}`,
      data: {},
    };
};

export const postPlan = (id) => (vehicleTypes) => {
  return {
    method: 'post',
    url: `/delivery/polish/plan/${id}`,
    data: vehicleTypes,
  };
};

export const fetchRoutesv1 = (id) => ({ meta, data }) => {
  return {
    meta,
    data: {
      method: 'post',
      url: `/delivery/polish/plan/${id}/path`,
      data,
    },
  };
};

export const fetchRoutes = (id, data) => ({
  method: 'post',
  url: `/delivery/polish/plan/${id}/path`,
  data,
});

export const fetchDistanceMatrices = (id, retailerIds) => {
  return {
    method: 'post',
    url: `/delivery/polish/plan/${id}/distanceMatrix`,
    data: retailerIds,
  };
};
